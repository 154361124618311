$primary:rgb(45, 98, 179);
$warning:rgb(255, 119, 7);

@import '/src/assets/theme/base-styles.scss';
  
@font-face {
    font-family: NunitoSans;
    src: url("../../../assets/fonts/NunitoSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: NunitoSansBlack;
    src: url("../../../assets/fonts/NunitoSans-Black.ttf") format("truetype");
}

@font-face {
    font-family: NunitoSansExtraBold;
    src: url("../../../assets/fonts/NunitoSans-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: NunitoSansSemiBold;
    src: url("../../../assets/fonts/NunitoSans-SemiBold.ttf") format("truetype");
}

body {
	font-family: 'NunitoSans';
	font-size: 22px;
}

.parent {
	position: relative;
	top: 0;
	left: 0;
}

.block-background {
	// background-color: rgba(80, 193, 180, 0.1);
	// border-radius: 10px;
	font-size: 0.75em;
	// padding: 5px;
	// margin-top: 5px;
	// margin-bottom: 5px;
}

.block-info {
	text-align: center !important;
	--bs-text-opacity: 1;
	color: #6c757d !important;
}

.grid-button {
	max-width: 250px;
}

.btn {
	--bs-btn-border-radius: 1.0rem;
	--white: #ffffff;
}

.btn-zain {
	--bs-btn-color: #004e5c;
	--bs-btn-bg: #ffffff;
	--bs-btn-border-color: #1f6470;
	--bs-btn-hover-color: #fff;
	--bs-btn-hover-bg: #1f6470;
	--bs-btn-hover-border-color: #565e64;
	--bs-btn-focus-shadow-rgb: 130, 138, 145;
	--bs-btn-active-color: #fff;
	--bs-btn-active-bg: #565e64;
	--bs-btn-active-border-color: #51585e;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: #fff;
	--bs-btn-disabled-bg: #6c757d;
	--bs-btn-disabled-border-color: #6c757d;
	--bs-btn-border-width: 2px;
}

.btn-zain-next {
	--bs-btn-color: white;
	background-image: linear-gradient(to right, #00515e, #009386) !important;
	background-repeat: repeat-x;
	margin-top: 4px;
	border: none;
}
.btn-zain-next:disabled {
/*	border-color: #1f6470;*/
/*	border: 2px solid rgb(31, 100, 112);*/
/*	border: 2px solid rgba(31, 100, 112, 1);*/
/* 	-webkit-background-clip: padding-box; for Safari */
/*    background-clip: padding-box;  for IE9+, Firefox 4+, Opera, Chrome */
    border: none;
/*    background-image:none !important;*/
/*    background-color: #b3d5d5;*/
    
}

.title {
	color: black;
	font-weight: bold;
}

.light-grey {
	background-color: #f9f9f9;
}

.step-title {
	color: black;
	font-weight: bold;
}

.step-info {
	font-size: small;
}
